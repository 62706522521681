<script setup lang="ts">
/**
 * @author Ia Gugunava
 * @refactor Beka Chkhaidze
 */
import { ref, watch } from "vue";

import { useBookingStore } from "@/store/booking";

import { required } from "@vuelidate/validators";

import { useRecaptcha } from "@/composables/core/recaptcha";
import { useFormValidator, DEFAULT_RULES } from "@/composables/utils/formValidator";

import Button from "../Button.vue";
import Input from "@/components/regularComponents/FormComponents/Input.vue";
import SmallLeft from "../SVG/chevrons/SmallLeft.vue";
import Recaptcha from "../Recaptcha.vue";

defineProps<{
  showBack?: boolean;
}>();
const emit = defineEmits(["clicked", "back"]);

const bookingStore = useBookingStore();
const isRecaptchaSolved = ref(false);
const isBtnDisabled = ref(false);

const clickButton = async () => {
  doValidation(true);
  let token;

  if (isFormValid.value) {
    isBtnDisabled.value = true;
    token = await useRecaptcha();
    isBtnDisabled.value = false;
    isRecaptchaSolved.value = Boolean(token);
  }

  if (!isFormValid.value || !isRecaptchaSolved.value) return;

  emit("clicked");
  bookingStore.$reset();
  resetState();
  isRecaptchaSolved.value = false;
};

const formData = ref<{
  name: string | null;
  email: string | null;
}>({
  name: null,
  email: null,
});

const rules = {
  name: DEFAULT_RULES.name,
  email: { required, ...DEFAULT_RULES.email },
};

const { isFormValid, errors, doValidation, resetState } = useFormValidator({
  formData,
  rules,
});
watch(bookingStore, (n) => {
  formData.value.name = n.name!;
  formData.value.email = n.email!;
});
</script>

<template>
  <div class="video-call">
    <Button
      v-if="showBack"
      class="video-call__back"
      :isBack="true"
      :btnType="8"
      @click="$emit('back')"
    >
      <SmallLeft />
      <h2 class="video-call__back_text">
        {{ $t("back") }}
      </h2>
    </Button>
    <h2 class="video-call__title">
      {{ bookingStore.getFormatedDate }} - {{ bookingStore.time }}
    </h2>
    <p class="video-call__paragraph">
      {{ $t("you are on a video call with a dome consultant") }}
    </p>
    <Input
      label="Name"
      type="text"
      :required="true"
      v-model="bookingStore.name"
      :errorText="errors?.name"
    />
    <Input
      label="email"
      type="email"
      :required="true"
      :errorText="errors?.email"
      v-model="bookingStore.email"
    />

    <div class="mb-16">
      <Recaptcha />
    </div>
    <Button
      btnText="schedule meeting"
      class="video-call__button"
      @click="clickButton"
      :is-disabled="isBtnDisabled"
    />
  </div>
</template>

<style lang="scss">
.video-call {
  &__title {
    @include weight(700);

    font-size: 36px;
    line-height: unset;
    margin-bottom: 16px;

    @include maxq(desktop-lg) {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 8px;
    }
  }
  &__paragraph {
    @include weight(400);

    font-size: 24px;
    line-height: unset;
    margin-bottom: 30px;

    @include maxq(desktop-lg) {
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 24px;
    }
  }
  .custom-input {
    margin-bottom: 16px;
  }
  &__button {
    width: 100%;
  }

  &__back {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: $cultured;
    width: max-content;
    padding: 14px 12px;
    margin-bottom: 24px;

    &_text {
      color: $modalDark;
      font-size: 15px;
      line-height: 20px;
      @include weight(400);
    }
  }
}
</style>
